@import '@/styles/theme/variables.scss';
@import '@usb-shield/react-button-group/dist/library/styles/scss/defaults.scss';

.TwelveColumnSubGridFix {
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}

@media #{$usb-max-lg} {
  .TwelveColumnSubGridFix {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }
}

@media #{$usb-max-md} {
  .TwelveColumnSubGridFix {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
}

/* fix for image not displaying when Nextjs Image component is set to layout='responsive' */
.responsiveColumn {
  width: 100%;
  height: 100%;
}

.imageBlock {
  width: 100%;
  margin-bottom: $token-spacing-6;

  img {
    object-fit: cover;
  }
}

/* end of responsive image fix */

.imageWrapper {
  width: 100%;
}

.headH2 {
  @include headingFont;
  line-height: $token-spacing-10;
  color: $token-text-color-brand-primary;
  margin-bottom: $token-spacing-4;
}

.headH2Eaf {
  font-family: $token-font-base;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: $token-font-weight-med;
  font-size: $token-font-size-lg;
  line-height: $token-spacing-7;
  color: $token-text-color-primary;
  padding-bottom: $token-spacing-2;
}

.headH2NoBottomMargin {
  @include headingFont;
  line-height: $token-spacing-10;
  color: $token-text-color-brand-primary;
  margin-bottom: $token-spacing-0;
}

.newHeadH2 {
  color: $token-text-color-primary;
  font-size: $token-font-size-xl;
  line-height: $token-spacing-12;
  font-weight: $token-font-weight-med;
}

.disclosureShortDescription {
  font-size: $token-font-size-xs;
  line-height: $token-spacing-base;
}

.disclosureHeader {
  font-size: $token-font-size-root;
  line-height: $token-spacing-6;
}

.paragraph {
  padding: $token-spacing-0 $token-spacing-4 $token-spacing-4 $token-spacing-0;

  ul {
    @include listUl;
    padding-inline-start: $token-spacing-10;
  }

  p {
    @include paragraphP;
  }
}

.mainContentStyling {
  @extend .paragraph;

  color: $token-color-grey-90;
}

.breadcrumbRow {
  background-color: $table-row-secondary-background-color;
}

.sectionRow {
  padding-top: $token-spacing-10;
  padding-bottom: $token-spacing-10;
}

.headH3 {
  font-size: $token-spacing-6;
  font-weight: $token-font-weight-med;
  line-height: $token-spacing-7;
  color: $token-color-grey-90;
  margin-bottom: $token-spacing-6;
}

.headH4 {
  font-size: $token-spacing-5;
  font-weight: $token-font-weight-med;
  line-height: $token-spacing-7;
  color: $token-color-grey-90;
  margin-bottom: $token-spacing-6;
}

.taxonomyLink {
  margin-right: $token-spacing-2 !important;
  font-weight: $token-font-weight-reg !important;
}

.solutionAreaLink {
  position: absolute;
  bottom: $token-spacing-1;
}

// Smaller than LG screen size
@media #{$usb-max-lg} {
  .headH3 {
    margin-bottom: $token-spacing-6;
  }

  .paragraph {
    margin-bottom: $token-spacing-0 !important;

    ul {
      margin-bottom: $token-spacing-0;
    }
  }
}
