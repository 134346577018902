@import '@/styles/theme/variables.scss';

.fullWidth {
  width: 100%;
  flex-direction: row;
  position: relative;
}

.blockArrayWrapperCtaSection {
  margin-bottom: $token-spacing-16;
}

.blockArrayShortDescription {
  margin-bottom: $token-spacing-20;
}

.blockArrayWrapperCtaButton {
  margin-left:$token-spacing-0 !important;
  margin-bottom:$token-spacing-0 !important;
}

.gridStyles {
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}

.marginLayout {
  margin-top: $token-spacing-10 !important;
}

.lightParagraph {
  p {
    padding: $token-spacing-0 $token-spacing-0 $token-spacing-2 $token-spacing-0;
    color: $token-utility-white !important;
  }
}

@media #{$usb-max-lg} {
  .gridStyles {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .blockArrayWrapperCtaButton {
    display: block;
    font-size: $token-font-size-root;
    margin: $token-spacing-0 !important;
    padding: $token-spacing-3 !important;
  }
  
  .marginLayout {
    margin-top: $token-spacing-30px !important;
  }
}

@media #{$usb-max-md} {
  .gridStyles {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
}

// Smaller than LG screen size
@media #{$usb-max-lg} {
  .blockArrayShortDescription {
    margin-bottom: $token-spacing-3;
  }
}

@media #{$usb-max-425} {
  .blockArrayWrapperCtaLink {
    font-size: $token-font-size-sm !important;
  }
}

// Smaller than SM screen size - Phone
@media #{$usb-max-sm} {
  .gridWrapper {
    padding-top: $token-spacing-0 !important;
    padding-bottom: $token-spacing-0 !important;
  }

  .marginLayout {
    margin-top: $token-spacing-10-8px !important;
  }
}

@media (min-width: $token-breakpoint-x-large) {
  .gridWrapper {
    max-width: $token-spacing-1650px !important;
  }
  .gridWrapperSections {
    padding-top: $token-spacing-0 !important;
    padding-bottom: $token-spacing-0 !important;
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .marginLayout,
  .fullWidth {
    max-width: $token-spacing-1120px !important;
  }
}
