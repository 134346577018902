@import './common.module.scss';

.imageDisplayImageRightLayout,
.imageDisplayImageLeftLayout {
  order: 1;
  width: 100%;
}

.imageWrapper {
  img {
    width: 100% !important;
  }
}
.gridStyles {
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  margin-top: $token-spacing-10 !important;
  margin-bottom: $token-spacing-10 !important;
}

.textDisplayImageRightLayout,
.textDisplayImageLeftLayout {
  order: 2;
}

.headH2NoBottomMargin {
  font-size: $token-spacing-8;
  font-weight: $token-font-weight-bold;
  line-height: $token-spacing-10;
  color: $token-text-color-brand-primary;
  margin-bottom: $token-spacing-0;
}

.paragraph {
  margin-bottom: $token-spacing-2;
  padding: $token-spacing-0;
  
  p {
    padding: $token-spacing-0 $token-spacing-0 $token-spacing-2;
    font-weight: $token-font-weight-reg;
  }
}

@media screen and (min-width: $token-breakpoint-large) {
  .textDisplayImageRightLayout {
    order: 1;
  }
  .imageDisplayImageRightLayout {
    order: 2;
  }
  .textDisplayImageLeftLayout {
    order: 2;
  }
  .imageDisplayImageLeftLayout {
    order: 1;
  }
}
@media #{$usb-max-lg} {
  .marginLayout {
    margin-top: $token-spacing-30px !important;
    margin-bottom: $token-spacing-30px !important;
  }
  .gridStyles {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }
}
@media #{$usb-max-md} {
  .gridStyles {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
}
@media #{$usb-max-sm} {
  .marginLayout {
    margin-top: $token-spacing-10-8px !important;
    margin-bottom: $token-spacing-10-8px !important;
    grid-row-gap: $token-spacing-0 !important;
  }
}
