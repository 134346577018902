@import './common.module.scss';

.paragraph {
  padding: $token-spacing-0 $token-spacing-4 $token-spacing-4 $token-spacing-0;
  flex-grow: 1;
}

.ctaButton {
  margin: $token-spacing-0 $token-spacing-0 $token-spacing-4 $token-spacing-0 !important;
  white-space: normal;
  word-wrap: break-word;
  min-height: $token-spacing-50px !important;
}

.imageWrapper {
  margin-bottom: $token-spacing-0;

  img {
    width: unset !important;
  }
}

.linkLayout{
  max-width: 100%;

  &::after {
    content: '';
    border: solid $token-background-brand-interaction;
    border-width: $token-border-width-0 $token-border-width-large $token-border-width-large $token-border-width-0;
    display: inline-block;
    padding: $token-spacing-1;
    transform: rotate(45deg);
    -webkit-transform: rotate(315deg);
    margin-left: $token-spacing-2;
    position: relative;
    top: (-$token-spacing-6);
  }
}

.solutionAreaLink {
  margin: $token-spacing-4 $token-spacing-0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none !important;

  &:hover {
    cursor: pointer;
    text-decoration: underline !important;
  }

  @include linkFocused;
}

@media #{$usb-max-sm} {
  .solutionAreaLink {
    max-width: 75%;
  }
}

@media screen and (min-width: $token-breakpoint-small) and (max-width: $token-breakpoint-medium) {
  .solutionAreaLink {
    max-width: 100%;
  }
}

@media screen and (min-width: $token-breakpoint-medium) and (max-width: $token-breakpoint-xx-large) {
  .solutionAreaLink {
    max-width: 85%;
  }
}
