@import './common.module.scss';

.paragraph {
  padding: $token-spacing-0;
  margin-bottom: $token-spacing-6;

  ul {
    margin-block-end: $token-spacing-2;
  }
}

.gridStyles {
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}

.fullWidth, .columnFullWidth {
  width: 100%;
  flex-direction: row;
  position: relative;
}

.lightBlueCtaLink {
  color: $twoColWithImage-lightBlueCtaLink-color !important;
}

.elavonLightBlueCtaLink {
  color: $twoColWithImage-lightBlueCtaLink-color !important;
  margin-bottom: $token-spacing-2;
}

@media #{$usb-max-lg} {
  .columnFullWidth {
    display: none !important;
  }
}


.textDisplayImageRightLayout {
  order: 2;
}

.imageDisplayImageRightLayout {
  order: 1;
}

.textDisplayImageLeftLayout {
  order: 2;
}

.imageDisplayImageLeftLayout {
  order: 1;
}

.ctaButton {
  margin-left: $token-spacing-0 !important;
}

.headH3 {
  margin-top: $token-spacing-0;
}

.lightHeadH3 {
  @extend .headH3;
  color: $token-utility-white;
}

.lightParagraph {
  @extend .paragraph;
  padding: $token-spacing-0;
  margin-bottom: $token-spacing-6;

  p {
    color: $token-utility-white;
  }

  ul {
    margin-block-end: 0.5rem;
    color: $token-utility-white;
  }
}

.responsiveImageCol {
  height: 100%;
}

.imageWrapper {
  height: 100%;

  img {
    width: 100%;
  }
}

.marginLayout {
  margin-top: $token-spacing-10 !important;
  margin-bottom: $token-spacing-10 !important;
}

.extendedMarginLayout {
  @extend .marginLayout;
  padding: $token-spacing-8 !important;
  background: $token-color-base-dark-blue-gray !important;
  border-radius: $token-spacing-2;
}

@media screen and (min-width: $token-breakpoint-large) {
  .textDisplayImageRightLayout {
    order: 1;
  }

  .imageDisplayImageRightLayout {
    order: 2;
  }
}

@media #{$usb-max-lg} {
  .paragraph {
    margin-bottom: $token-spacing-6 !important;

    ul {
      margin-bottom: $token-spacing-2 !important;
    }
  }

  .marginLayout {
    margin-top: $token-spacing-30px !important;
    margin-bottom: $token-spacing-30px !important;
  }

  .gridStyles {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }
}
@media #{$usb-max-md} {
  .gridStyles {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
}

@media #{$usb-max-sm} {
  .paragraph {
    margin: $token-spacing-1;
  }
  
  .marginLayout {
    margin-top: $token-spacing-10-8px !important;
    margin-bottom: $token-spacing-10-8px !important;
    grid-row-gap: $token-spacing-4 !important;
  }
}
